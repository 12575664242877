import React from "react"
import Page from "../../components/page"
import TextLeft from "../../components/textLeft"
import { graphql } from "gatsby"
import TextRight from "../../components/textRight"

export default function Debossing({ location, data }) {
  return (
    <Page title={"What is debossing – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>WHAT IS DEBOSSING? </div>}
        subtitle=""
        text={
          <div>
            Debossing is the processes of creating recessed relief images and designs in paper, leather and other materials. Debossing can be used in combination with foil stamping to add depth and impact to a design.
        <br/>
        <br/>
            This can be done using metal types (we have several fonts and sizes available) or using custom dies if you need a custom graphic like a logo or a font that we do not have.
            <br/>
            <br/>
            You can order covers in blind deboss (no foil) or using gold, silver, cream foils.
          </div>
        }
        data={data}
        image={'debossingWhatIs'}
      />
      <TextRight
        title={<div>DEBOSSING USING OUR <br/>METAL TYPES </div>}
        subtitle=""
        text={
          <div>
            We arrange individual metal types into a type holder, forming words from the combination of letters and symbols. The finished surface, constructed from moveable metal types is pressed into the cover resulting in a deep impression. This can be done using foil or no foil. The process is called blind deboss when no foil is being used.
            <br/>
            <br/>
            The resulting depth depends on the material thickness (for example leather is thicker than linen).
          </div>
        }
        data={data}
        image={'guestbooksTeam'}
      />

    </Page>
  )
}


export const query = graphql`
  query {
    debossingWhatIs: file(relativePath: { eq: "debossingWhatIs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
    guestbooksTeam: file(relativePath: { eq: "guestbooksTeam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
  }
`